import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import AppForm from '../../../components/AppForm';
import AppInput from '../../../components/AppInput';
import AppTextArea from '../../../components/AppTextArea';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';

import './style.scss';
import ConsentCheckbox from '../../../components/ConsentCheckbox';

const Top5MistakesMSPSMake = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Top 5 Mistakes MSPs Make that prevent killer growth" />
            <div className="top-5-mistakes-msps-make">
                <Container className="blue-gradient-background  container-1">
                    <Row>
                        <Col className="centered-text">
                            <p className="hero-subtext white-text">
                                Webinar Summary
                            </p>
                            <h1 className="max-width-1500">
                                Top 5 mistakes MSPs make that prevent killer
                                growth
                            </h1>
                        </Col>
                    </Row>
                    <div className="video-container">
                        <WistiaPopoverVideo id="fh2xwf8ch0">
                            <img
                                className="thumbnail"
                                src={require('../../../images/webinars/top-5-mistakes-thumbnail.png')}
                                alt="video thumbnail"
                            />
                        </WistiaPopoverVideo>
                    </div>
                </Container>
                <Container className="grey-swish-background container-2 ">
                    <Row>
                        <Col>
                            <p className="max-width-1000">
                                GlassHive and Pronto Marketing teamed up to talk
                                more about the mistakes MSPs are making that are
                                hindering their sales and marketing growth.
                                Check out these helpful tips for taking your
                                sales and marketing efforts to the next level
                                for your MSP and how to fix these mistakes.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3 max-width-1500">
                    <Row>
                        <Col className="one-fourth">
                            <img
                                src={require('../../../images/webinars/clipboard.png')}
                                alt="Clipboard"
                            />
                        </Col>
                        <Col className="three-fourth">
                            <h3>Mistake 1</h3>
                            <h2>Not Starting with a plan</h2>
                            <p>
                                It’s easy to get hyper focused on your goals.
                                But when it comes close to the time of actually
                                meeting those goals, you may fall short. This is
                                a good time to pause everything. Rather than
                                constantly reacting to what’s going on here and
                                now, shift your focus to create a plan that can
                                actually be achieved. When dialing back, there
                                are a few things to think about in order to form
                                your goals.
                            </p>
                            <p className="bold margin-bottom-0">
                                What to include in your marketing plan:
                            </p>
                            <ul>
                                <li>Where you stand today and your goals</li>
                                <li>Do a competitor analysis</li>
                                <li>Core focus and differentiation</li>
                                <li>Define brand messaging and style</li>
                                <li>Build out a 90 day plan</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-fourth">
                            <img
                                src={require('../../../images/webinars/target.png')}
                                alt="Target"
                            />
                        </Col>
                        <Col className="three-fourth">
                            <h3>Mistake 2 </h3>
                            <h2>Not knowing your audience</h2>
                            <p>
                                If you don’t know who your audience is, how can
                                you create content tailored to their needs and
                                interests? You have to know yourself first. What
                                makes your MSP special? What’s your personality,
                                tone, and theme? By starting internally, you can
                                better serve your audience. How do you want your
                                employees and partners to see who you are? With
                                this, it’s also important to remember that you
                                can’t please everyone. But don’t try to be
                                generic at the same time. By understanding the
                                most common questions in the industry, you can
                                create content that builds a resource library.
                                This allows you to establish credibility
                                quickly.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-fourth">
                            <img
                                src={require('../../../images/webinars/monitor.png')}
                                alt="Monitor"
                            />
                        </Col>
                        <Col className="three-fourth">
                            <h3>Mistake 3 </h3>
                            <h2>Working with an ineffective website</h2>
                            <p>
                                You can’t just use intuition when building out a
                                website. You have to look at the stats and
                                understand what people are searching for, what
                                do they do, and do you have a page on your site
                                that answers that? Have a sitemap that is backed
                                by research.
                            </p>
                            <p>
                                Don’t be afraid to personalize your website.
                                Using actual photos and videos of your team goes
                                a lot further than just using stock photos. This
                                gives the user a way to see how your business
                                genuinely is. People crave personal connection.
                                Have a clear primary call-to-action. Giving too
                                much information by introducing features and
                                solutions can get overwhelming. You want to
                                create a sense of curiosity and remember what
                                the end goal of the form is. However, not
                                everyone is going to fill out a form and be
                                ready to go. Having secondary call-to-actions is
                                a more creative way to get leads. You still need
                                to get emails for your lists. So instead of
                                offering a generic eBook, try something like a
                                webinar or an event for prospects to sign up
                                for. Get creative with your offers and something
                                that drives value.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-fourth">
                            <img
                                src={require('../../../images/webinars/phone.png')}
                                alt="Phone"
                            />
                        </Col>
                        <Col className="three-fourth">
                            <h3>Mistake 4</h3>
                            <h2>Lack of sufficient activity</h2>
                            <p>
                                It takes some time to get to the discovery call.
                                On average it takes about 12 activities to get
                                to the first discovery call. After that, it
                                generally takes about 2 phone calls to get to an
                                in-person meeting. Then, there are usually 2
                                more meetings to arrive at the proposal stage.
                                And even with all that work, normally only 1 out
                                of 3 proposals actually close. It takes a lot of
                                work! By not staying on top of leads and
                                following up, your relationship can fall by the
                                wayside. Having a fully built out sales process
                                helps your sales team to follow sales activity
                                and stay consistent. So just keep at it and
                                understand it takes time to close a sale.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-fourth">
                            <img
                                src={require('../../../images/webinars/chatBoxWithGraph.png')}
                                alt="Chat box with graph"
                            />
                        </Col>
                        <Col className="three-fourth">
                            <h3>Mistake 5</h3>
                            <h2>Not measuring success</h2>
                            <p>
                                Chances are you have a lot of targets to watch.
                                But having KPIs that are actively measuring
                                success is the biggest thing. Tracking form
                                submissions is a small piece of the puzzle for
                                tracking leads. There’s a combination of many
                                other factors that go into converting leads.
                                With that being said, it’s important to build a
                                sales funnel that nurtures leads and can be
                                tracked throughout the process.
                            </p>
                            <p className="bold margin-bottom-0">
                                Numbers to watch:
                            </p>
                            <ul>
                                <li> Email open and click through rate</li>
                                <li>Email health (bounces, spam)</li>
                                <li>Website traffic</li>
                                <li>
                                    Sales activities (emails, calls, meetings,
                                    close %)
                                </li>
                                <li>Average time to close (47 days)</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4">
                    <Row>
                        <Col className="centered-text max-width-700">
                            <AppForm
                                explainerText={`
                                  <h3 class="centered-text white-text">Still need some extra assistance with your MSPs sales and marketing? We have your back. Sign up to receive more educational info like this and continue to grow your MSP. </h3>`}
                                formName="Pronto-Webinar"
                                submitBtnText="Sign Up"
                            >
                                <AppInput
                                    fieldName="name"
                                    type="text"
                                    placeholder="Name"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    placeholder="Email"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="company"
                                    type="text"
                                    placeholder="Company"
                                    requiredField
                                />
                                <AppTextArea
                                    fieldName="Comments"
                                    placeholder="Comments"
                                    requiredField
                                />
                                <ConsentCheckbox />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Top5MistakesMSPSMake;
